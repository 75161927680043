<template>
  <div class="parents" style="padding-top:0">
    <div class="branch">
      <img width="200" height="75" src="@/assets/swappie.png">
    </div>

    <div class="header">
      <a-image  width="120" :src="imageUrl"/>
      <div class="phone-info">
        <div class="phone-name">{{ phoneName }}</div>
        <div class="phone-price">{{ phonePrice }}</div>
        <div class="phone-imei">{{ phoneImei }}</div>
      </div>
    </div>

    <div class="info-tab">{{$t('payment.title.paymentInfo')}}</div>
    <div class="info-des">{{$t('payment.title.description')}}</div>

    <!-- <a-input class="cell-margin-top"  v-model="username" allow-clear>
      <template #prefix>
        Name：
      </template>
    </a-input> -->


    <span class="input-label"><span>{{$t("payment.input.label.email")}}<em>*</em></span></span>
    <a-input class="cell-margin-top input-fillet" v-model="email" maxLength="60" allow-clear >
    </a-input>

    <span class="input-label">{{$t("payment.input.label.phoneNumber")}}<em>*</em></span>
    <a-input class="cell-margin-top input-fillet" v-model="phoneNumber"   maxLength="30" allow-clear>
    </a-input>

    <a-divider />
    <!-- <a-button v-if="getBankVisible" class="cell-margin-top button-fillet" style="height: 40px;" type="outline" long @click="getBank()">{{$t('payment.button.text.getBank')}}</a-button>
    <div class="bank-id" v-if="getBankVisible">
      <img  width="100" height="95" src="@/assets/bank-id.png"/>
    </div> -->
    <span   class="input-label">{{$t("payment.input.label.clearingNumber")}}<em>*</em></span>
    <a-input v-if="clearningNumberInputVisible"  class="cell-margin-top  input-fillet"  v-model="clearingNumber"    maxLength="30" allow-clear>
    </a-input>
    
    <div v-if="!clearningNumberInputVisible" class="cell-margin-top bank-number input-fillet" >
      <span>{{ clearingNumber }}</span>
        <img  v-show="clearingNumber" src="@/assets/input-success.png"/>
    </div> 

    <span  class="input-label">{{$t("payment.input.label.accountNumber")}}<em>*</em></span>
    <div  class="cell-margin-top bank-number  input-fillet"  >
      <span>{{ accountNumber }}</span>
      <img v-show="accountNumber"  src="@/assets/input-success.png"/>
    </div>

    <a-button  class="button-fillet" style="margin-top: 20px; height: 40px;"  :style="submitBtnStyle" type="primary" long value="large" @click="goTo()" >{{$t('payment.button.text.submit')}}</a-button>

    <!-- <div class="info-des" style="margin-top: 10px;">Please confirm your filled in info on the kiosk later</div> -->
    <a-modal :visible="confirmModalVisible" width="70%"  :footer="false" simple="true">
    <template #title>
    </template>
    <div class="modal-parent">
      <div>
        <img src="@/assets/success.png">

      </div>
      <div class="confirmMessage">
        {{$t('payment.modal.confirmMessage')}}
      </div>
      <a-button  class="button-fillet" style="margin-top: 20px; height: 40px;"  :style="submitBtnStyle" type="primary" long value="large" @click="goTo()" >{{$t('payment.button.text.submit')}}</a-button>

    </div>
  </a-modal>
  </div>
</template>

<script>
import { submit, tinkInfo,reportInfo,saveTinkReportLog } from "@/utils/api"
import util from '@/utils/util'
export default {
  data() {
    return {
      imageUrl: require("@/assets/image-default.png"),
      username: "",
      phoneNumber: "",
      email: "",
      clearingNumber: "",
      accountNumber: "",
      phoneName: "",
      phonePrice: "",
      phoneImei: "",
      key: "",
      state:"",
      submitDisabled : false,
      reportId:"",
      submitBtnStyle:"background-color:#222C9B",
      getBankVisible:false,
      clearningNumberInputVisible:false,
      confirmModalVisible:false,
    };
  },
  computed(){

  },
  created() {
    let locale = util.getUrlKey('locale')
    if(locale){
      localStorage.setItem("locale",locale)
    }else{
      locale = localStorage.getItem("locale")
    }
    if(!locale){
      locale= 'en_US'
    }
    this.$i18n.locale=locale
    //设置标题
    document.title = this.$t('payment.title.paymentInfo')
    // this.key = util.getUrlKey('key')
    // if(this.key){
    //   this.getBankVisible=true
    // }else {
    this.state = util.getUrlKey('state')
    // }
    let reportId = util.getUrlKey('account_verification_report_id')
    if (reportId) {
      this.reportId = reportId
    }
    console.log("key: " + this.key + ",reportId: " + this.reportId)
    this.getTinkInfo();
    // if (this.reportId) {
    //   this.getReport()
    // }
  },

  methods: {
    getTinkInfo() {
      tinkInfo(this.key||this.state).then((result) => {
        this.phoneName = result.data.productName
        this.phonePrice = result.data.price + " " + result.data.currencySymbol
        this.phoneImei = "IMEI: " + result.data.imei1.replace(/^(.{4})(?:\w+)(.{4})$/, "\$1***\$2")
        this.imageUrl = result.data.productImageUrl
        //当回调的时候才展示这三个值
        if(this.state){
          if(result.data.status!='Order'){
            this.username = result.data.paymentName
            this.email = result.data.paymentEmail
            this.phoneNumber=result.data.paymentPhoneNumber
            this.getReport()
          }else{
             this.toast(this.$t('payment.toast.invalidRequest')) 
          }

        }

 
      }).catch((err) => {
        this.toast(err)
      })
    },
    getReport() {
      if(!this.reportId){
        try{
          console.log("util.getUrlParams()",util.getUrlParams())
            saveTinkReportLog({reportId:this.reportId,reportInfo:JSON.stringify(util.getUrlParams()),reportKey:this.state})
            .then((saveResult)=>{
              console.log(saveResult)
            });
          }catch(e){
            console.log(e)
          }
        return 

      }
      reportInfo(this.reportId).then((result) => {
        console.log(result)
        if(result&&result.userDataByProvider){
          //let info = result.userDataByProvider[0]?.accounts[0]?.accountIdentifiers?.rix
          //if(info){
          let account = result.userDataByProvider[0]?.accounts[0]
          this.clearingNumber=account.holderName||result.userDataByProvider[0]?.identity?.name
          this.accountNumber=account.iban
          if(this.accountNumber&&!this.clearingNumber){
            this.clearningNumberInputVisible=true
          }
          if(this.clearingNumber&&this.accountNumber) {
              this.confirmModalVisible = true
          }
            
          // else{
          //   this.getBankVisible=true
          //   //this.toast(this.$t('payment.toast.dataIncomplete')) 
          //   //this.toast("The data is incomplete, please change the account number or enter it on the machine")
          // }
          try{
            saveTinkReportLog({reportId:this.reportId,reportInfo:JSON.stringify(result),reportKey:this.state})
            .then((saveResult)=>{
              console.log(saveResult)
            });
          }catch(e){
            console.log(e)
          }
        }

        //this.submitDisabled = !(this.clearingNumber && this.accountNumber)
        this.submitBtnStyle = this.submitDisabled?"background-color:#dadada":"background-color: #222C9B"
        try{
          setTimeout(()=>{
            window.scrollTo(0, document.body.scrollHeight)

          },500)
        }catch(e) {
        }
          
      }).catch((err) => {
        this.toast(err)
      })
      
    }, 
    getBank() {
      this.submit(1,(url) => {
        window.location.href = url;
      })
    },
    goTo() {
      this.submit(2,(url) => {
        this.$router.push('/success');
      })
    },
    submit(type,  callback) {
      // if (this.isNull(this.username)) {
      //   this.toast("Please enter your username")
      //   return;
      // }
     
      const regEmail = /^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\.)+[a-z]{2,}$/;
      if (regEmail.test(this.email) == false) {
        this.toast(this.$t('payment.toast.invalidEmail')) 
        //this.toast("The email format is incorrect(＜60)")
        return;
      }
       if (this.isNull(this.phoneNumber)) {
        this.toast(this.$t('payment.toast.enterPhoneNumber')) 
        //this.toast("Please enter your phone number")
        return;
      }
      console.log("this.key",this.key)

      let params = {
        //"name": this.username,
        "phoneNumber": this.phoneNumber,
        "email": this.email,
        "name": type == 1 ? undefined : this.clearingNumber,
        "bankCardNo": type == 1 ? undefined : this.accountNumber,
      }
      if(!this.clearingNumber&&!this.accountNumber){
        this.toast(this.$t('payment.toast.dataIncomplete'))
        return 
      }
      if(!this.clearingNumber||this.clearingNumber.length>30){
         this.toast(this.$t('payment.toast.invalidName'))
        return 
      }
      
      submit(params, this.state).then((result) => {
        callback(result.data.url)
      }).catch((err) => {
        this.toast(err)
      })
    },

    toast(content) {
      this.$message.clear()
      this.$message.normal({
        content: content,
        position: 'bottom'
      })
    },

    isNull(str) {
      if (str == "") return true;
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      return re.test(str);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
:root {
  --color-text-4: #333333; /* 修改后的背景颜色 */
}
.parents {
  padding: 30px;
  text-align: left;
}

.header {
  align-items: center;
  display: flex;
}
.branch{
  display: flex;
  justify-content: left;
}

.bank-id{
  display: flex;
  justify-content: center;
}

.phone-info {
  text-align: left;
  margin-left: 20px;
}

.phone-name {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.phone-price {
  font-size: 25px;
  font-weight: bold;
  margin: 15px 0 15px 0;
}

.cell-margin-top {
  margin-top: 10px;
  margin-bottom: 10px;

}
.font-input{
  color: #333333;
}

.phone-imei {
  font-size: 16px;
  color: #333333;
}

.info-tab {
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
  color: #333333;
}

.info-des {
  font-size: 14px;
  margin: 5px 0 10px 0;
  color: #333333;
}

.arco-btn-outline, .arco-btn-outline[type='button'], .arco-btn-outline[type='submit'] {
    color: white;
    background-color: #222C9B;
    border: 1px solid #222C9B;
    /* font-color:white; */

}

.arco-input-wrapper.arco-input-focus{
  z-index: 1;
  background-color: var(--color-bg-2);
  border-color: #55B1FC;
  box-shadow: 0 0 0 0 var(--color-primary-light-2);
}

.submitBtn{
  background-color: #55B1FC
}

.submitBtnDisabled{
  background-color:#dadada
}

.bank-number {
  align-items: center;
  color: var(--color-text-2);
  background-color:#F5F5F5;
  cursor: not-allowed;
  display: inline-flex;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  padding: 0 12px;
  font-size: 14px;
  background-color: var(--color-fill-2);
  border: 1px solid #939393;
  padding-right: 5px;

}
.bank-number img{
  width: 16px;
  height: 16px;
}
.input-label{
 
}
.input-label em{
  color: red
}

.input-fillet{
  border-radius:8px;
}

.button-fillet{
  border-radius:20px;
}

.arco-divider.arco-divider-horizontal{
  border-bottom:1px solid #939393
}
.arco-input-wrapper.cell-margin-top.input-fillet{
  border-color: #939393;
  background-color: white
}
.modal-parent{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
}
.modal-parent img {
  width: 100px;
  height: 100px;
}
.modal-parent .confirmMessage{
  text-align: center;

}




</style>
