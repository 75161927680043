<template>
    <div class="loading" v-show="msg.show">
        <div class="load-box">
            <Spin class="spin" />
            <span>{{ msg.title }}</span>
        </div>
    </div>
</template>

<script>
import { Spin } from '@arco-design/web-vue';
export default {
    components: {
        Spin,
    },
    name: 'loading',
    props: {
        msg: Object,
    }
}
</script>

<style scoped>
.loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.load-box {
    background-color: rgba(0, 0, 0, .5);
    width: 80px;
    height: 80px;
    border-radius: 5px;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, .5);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    letter-spacing: .8px;
    font-size: 12px;
}

.spin {
    width: 30px;
    margin-bottom: 8px;
    margin-left: 8px;
}

/* @keyframes rotate {
    to {
        transform: rotate(360deg);
    }
} */
</style>