import { createApp, reactive } from 'vue'

import myLoad from '@/components/Loading.vue'
import i18n from '@/locales/index'

const msg = reactive({
  show: false,
  //title: i18n.global.t('loading.message')
})
//console.log(i18n.global,"i18n.global")
const $loading = createApp(myLoad, {msg}).mount(document.createElement('div'))
const load = {
  show(title = i18n.global.t('loading.message')) { // 控制显示loading的方法
    msg.show = true
    msg.title = title
    document.body.appendChild($loading.$el)
  },

  hide() { // 控制loading隐藏的方法
    msg.show = false
  }
}
export  { load }
