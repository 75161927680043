export default {
    'payment.input.label.phoneNumber' : 'Telefonnummer' ,
    'payment.input.label.email' : 'E-mail' ,
    'payment.title.paymentInfo' : 'Kontoverifiering och betalning' ,
    'payment.title.description' : 'Fyll i dina kontaktuppgifter och tryck på “Hämta bankkonto” för att verifiera ditt konto med ditt BankID' ,
    'payment.button.text.getBank' : 'HÄMTA BANKKONTO' ,
    'payment.input.label.clearingNumber' : 'Namn' ,
    'payment.input.label.accountNumber' : 'IBAN kontonummer' ,
    'payment.button.text.submit' : 'FORTSÄTT' ,
    'payment.toast.invalidRequest' : 'Begäran är ogiltig. Skanna koden igen' ,
    'payment.toast.dataIncomplete' : 'Uppgifterna är ofullständiga, skanna koden i kiosken, byt konto och försök igen' ,
    'payment.toast.enterPhoneNumber' : 'Ange ditt telefonnummer' ,
    'payment.toast.invalidEmail' : 'E-postformatet är felaktigt(<60)' ,
    'paymentResult.div.success' : 'Verifieringen lyckades!' ,
    'paymentResult.div.describe' : 'Vänligen fortsätt transaktionen på kiosken' ,
    'loading.message' : 'lastning...' ,
    'payment.toast.invalidName' : 'Namnformatet är felaktigt(<30)' ,
    'payment.modal.confirmMessage' : 'Tink data erhölls framgångsrikt' ,
}