export default {
  'payment.input.label.phoneNumber' : 'Phone number' ,
  'payment.input.label.email' : 'E-mail' ,
  'payment.title.paymentInfo' : 'Payment and account verification' ,
  'payment.title.description' : 'Please fill in your contact details and click on “Get bank account” below to connect your account' ,
  'payment.button.text.getBank' : 'GET BANK ACCOUNT' ,
  'payment.input.label.clearingNumber' : 'Full name' ,
  'payment.input.label.accountNumber' : 'IBAN account No.' ,
  'payment.button.text.submit' : 'CONTINUE' ,
  'payment.toast.invalidRequest' : 'The request is invalid. Please scan the code again' ,
  'payment.toast.dataIncomplete' : 'The data is incomplete, Please scan the code on the kiosk, change the account and try again' ,
  'payment.toast.enterPhoneNumber' : 'Please enter your phone number' ,
  'payment.toast.invalidEmail' : 'The email format is incorrect(＜60)' ,
  'paymentResult.div.success' : 'Bank account successfully connected!' ,
  'paymentResult.div.describe' : 'Please continue the transaction on the kiosk',
  'loading.message' : 'loading...',
  'payment.toast.invalidName' : 'The name format is incorrect(＜30)' ,  
  'payment.modal.confirmMessage' : 'Tink data was obtained successfully' ,
}