import axios from "axios";
import { load } from '@/utils/loading.js';

// 创建 axios 请求实例
const serviceAxios = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 基础请求地址
  timeout: 10000, // 请求超时设置
  withCredentials: false, // 跨域请求是否需要携带 cookie
});


// 创建请求拦截
serviceAxios.interceptors.request.use(
  (config) => {
    load.show()
    config.headers["Authorization"] = localStorage.getItem("token"); // 请求头携带 token
    config.headers['Accept-Language'] = localStorage.getItem("locale")?.replace("_","-")||"en-US"
    console.log("请求配置", config);
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 创建响应拦截
serviceAxios.interceptors.response.use(
  (res) => {
    load.hide()
    let data = res.data;
    // 处理自己的业务逻辑，比如判断 token 是否过期等等
    // 代码块
    return data;
  },
  (error) => {
    load.hide()
    console.log("请求异常", error);
    let message = error.message;
    if (error && error.response) {
      switch (error.response.status) {
        case 302:
          message = "The interface was redirected!";
          break;
        case 400:
          message = error.response.data.error.message;
          break;
        case 401:
          message = "You are not logged in, or login has timed out, please log in first!";
          break;
        case 403:
          message = "You do not have permission to operate!";
          break;
        case 404:
          message = `Request address error: ${error.response.config.url}`;
          break;
        case 408:
          message = "Request timed out!";
          break;
        case 409:
          message = "The same data already exists in the system!";
          break;
        case 500:
          message = "Server internal error!";
          break;
        case 501:
          message = "Service not realized!";
          break;
        case 502:
          message = "Gateway error!";
          break;
        case 503:
          message = "Service unavailable!";
          break;
        case 504:
          message = "Service is temporarily unavailable, please try again later!";
          break;
        default:
          message = "Abnormal problem, please contact the administrator!";
          break;
      }
    }
    return Promise.reject(message);
  }
);
export default serviceAxios;