<template>
  <div class="parents">
    <img src="@/assets/success.png">
    <div class="info-tab">{{$t('paymentResult.div.success')}}</div>
    <div class="info-des">{{$t('paymentResult.div.describe')}}</div>

  </div>
</template>

<script>
export default {
  imageUrl: ''
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.parents {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.info-tab {
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
}

.info-des {
  font-size: 20px;
  margin-top: 30px;
}

img {
  width: 100px;
  height: 100px;
}
</style>
