import serviceAxios from "./request";

export const submit = (data, key) => {
    return serviceAxios({
        url: "/mta-swappie-service/mta/web/evaluation/payment/tink/bank/" + key,
        method: "post",
        data,
    });
};

export const tinkInfo = (key) => {
    return serviceAxios({
        url: "/mta-swappie-service/mta/web/evaluation/payment/tink/info/" + key,
        method: "get"
    });
};

export const reportInfo = (reportId) => {
    return serviceAxios({
        url: "/mta-swappie-service/mta/iban/tink/report",
        method: "get",
        params:{reportId}
    });
};

export const saveTinkReportLog = (data) => {
    return serviceAxios({
        url: "/mta-swappie-service/mta/iban/tink/report/log",
        method: "post",
        data
    });
};