import { createApp } from 'vue'
import App from './App.vue'
import ArcoVue from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';
import { createRouter } from 'vue-router';
import { createWebHashHistory } from 'vue-router';
import PaymentInfo from './components/PaymentInfo.vue';
import Tink from './components/Tink.vue';

import Success from './components/Success.vue';
import Vconsole from 'vconsole';
import i18nInstance from './locales'


const routes = [
    { path: '/tink', component: Tink },
    { path: '/', component: PaymentInfo,title:"payment info" },
    { path: '/success', component: Success },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

const app = createApp(App);
app.use(router)
app.use(ArcoVue);
app.use(i18nInstance)
app.mount('#app');
// app.use(new Vconsole())
//Vue.prototype.$t = (key, value) => i18nInstance.t(key, value);

