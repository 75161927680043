import { createI18n } from 'vue-i18n'
import sv_SE from './sv_SE'
import en_US from './en_US'

const vueI18n = createI18n({
  locale: 'en_US',
  legacy: false,
  globalInjection:true,
  messages: {
    sv_SE,
    en_US
  },
})

export default vueI18n