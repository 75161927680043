export default{
    getUrlKey: function (name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
    },
    getUrlParams: function () {
        let paramsMap = {}
        try{
            location.search.replace("?","").split("&").forEach(it=>{
                let params = it.split('=')
                if(params.length>1){
                    paramsMap[params[0]]=decodeURIComponent(params[1])
                }
            })
        }catch(e){
            console.log(e)    
        }
        return paramsMap
        
    }
}